export const parseExperiencerToModel = (experiencer) => ({
    id: experiencer?.id,
    name: experiencer?.name,
    status: experiencer?.status,
    pictureURL: experiencer?.pictureURL,
    addressStreet: experiencer?.addressStreet,
    addressStreetAlt: experiencer?.addressStreetAlt,
    addressCity: experiencer?.addressCity,
    addressState: experiencer?.addressState,
    addressCountryId: experiencer?.addressCountryId,
    addressPC: experiencer?.addressPC,
    other: experiencer?.other,
    officePhone: experiencer?.officePhone,
    mobilePhone: experiencer?.mobilePhone,
    email: experiencer?.email,
    emailAlt: experiencer?.emailAlt,
    emergencyDetails: experiencer?.emergencyDetails,
    emergencyContact: experiencer?.emergencyContact,
    billingDetails: experiencer?.billingDetails,
    companySize: experiencer?.companySize,
    addressCountry: experiencer?.addressCountry,
    description: experiencer?.description,
  });
  
//   export const parseTriperToObject = (data) => ({
//     ...data,
//   });
  
//   export const parseBookingToObject = (booking) => {
//     // console.log('Parsing booking...', booking);
//     return {
//       code: booking.code,
//       id: booking.id,
//       experienceId: booking.experienceId,
//       experienceDetailId: booking.experienceDetailId,
//       email: booking.email,
//       triper: `${booking.firstName} ${booking.lastName}`,
//       mobilePhone: booking.mobilePhone,
//       mobilePhoneCountryCode: booking.mobilePhoneCountryCode,
//       experienceName: booking.experienceName,
//       paidPrice: booking.paidPrice,
//       totalPrice: booking.totalPrice,
//       toBePaidPrice: booking.totalPrice - booking.paidPrice,
//       tickets: booking.quantity,
//       status: booking.status,
//       originId: booking.originId,
//       isEditing: false,
//       date: booking.date,
//       formattedDate: {
//         date: booking.date.slice(0, booking.date.indexOf('T')),
//         time: booking.date.slice(
//           booking.date.indexOf('T') + 1,
//           booking.date.lastIndexOf(':')
//         ),
//       },
//       // date: booking.date.slice(0, booking.date.indexOf('T')),
//       // hour: booking.date.slice(booking.date.indexOf('T') + 1, booking.date.lastIndexOf(':')),
//     };
//   };
  