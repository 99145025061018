import React, { useContext, useState } from 'react';
import { ContractService } from 'core/api/services/contract';

const ContractContext = React.createContext(null);

export const ContractProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [contracts, setContracts] = useState([]);
  const [contract, setContract] = useState([]);
  const [contractExperiences, setContractExperiences] = useState([]);
  const [total, setTotal] = useState([]);
  const [totalExperiences, setTotalExperiences] = useState([]);

  const getContracts = async (options) => {
    try {
      
      setLoading(true);
      const result = await ContractService.getList(options);

      if (!result) {
        throw new Error();
      }
      setContracts(result.contracts);
      setTotal(result.total)
      return true;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const getContractExperience = async (id,options) => {
    try {
      
      setLoading(true);
      const result = await ContractService.getExperienceList(id,options);

      if (!result) {
        throw new Error();
      }
      setContractExperiences(result.experiences);
      setTotalExperiences(result.total);
      return true;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const getContract = async (id) => {
    try {
      
      setLoading(true);
      const result = await ContractService.get(id);
      if (!result) {
        throw new Error();
      }
      setContract(result);
    //   setContractExperiences(result.experiences?.experiences);
      setTotalExperiences(result.experiences?.total);
      return true;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const addContract = async (values) => {
    try {
      setLoading(true);
      const result = await ContractService.add(values);
      if (!result) {
        throw new Error();
      }
      setContract(result);
      return result;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const updateContract = async (values) => {
    try {
      setLoading(true);
      const result = await ContractService.update(values);
      if (!result) {
        throw new Error();
      }
    //   getUserInfo();
      return result;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const updateContractExperiences = async (id, values) => {
    try {
      setLoading(true);
      const result = await ContractService.updateExperiences(id, values);
      if (!result) {
        throw new Error();
      }
      return result;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const deleteContractExperiences = async (id, expId) => {
    try {
      setLoading(true);
      const result = await ContractService.deleteExperiences(id, expId);
      if (!result) {
        throw new Error();
      }
      return result;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      setLoading(false);
    }
  };

  return (
    <ContractContext.Provider
      value={{
          loading,
          total,
          totalExperiences,
          contract,
          contractExperiences,
          contracts,
          setContract,
          getContract,
          getContractExperience,
          getContracts,
          addContract,
          updateContract,
          updateContractExperiences,
          deleteContractExperiences,
      }}>
      {children}
    </ContractContext.Provider>
  );
};

ContractProvider.displayName = 'ContractProvider';

export const useContract = () => useContext(ContractContext);
