import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: '1rem',
  },
  checkbox: {
    color: `${theme.palette.secondary.main} !important`,
  },
}));
