import { fetch, Method, getPaging } from 'core/api/fetch';
import { parseContractToModel, parseContractToObject } from 'core/api/parser/contract';

const ContractRoutes = {
    get: (id) => `/api/v1.1.0/admin/contract/${id}`,
    getList: (options) => `/api/v1.1.0/admin/contract?${getPaging(options)}`,
    getExperienceList: (id,options) => `/api/v1.1.0/admin/contract/${id}/experience?${getPaging(options)}`,
    add: '/api/v1.1.0/admin/contract',
    update: (id) => `/api/v1.1.0/admin/contract/${id}`,
    updateExperiences: (id) => `/api/v1.1.0/admin/contract/${id}`,
    deleteExperiences: (id, expId) => `/api/v1.1.0/admin/contract/${id}/experience/${expId}`,
    uploadImage: (id) => `/api/v1.1.0/admin/contract/${id}/image`,
    getQRCode: (id) => `/api/v1.1.0/admin/contract/${id}/qr`,
};

export const ContractService = {

    get: async (id) => {
        try {
            const { data } = await fetch(ContractRoutes.get(id), {
                method: Method.GET,
                authenticated: true
            });
            return !data || !data.length ? {} : parseContractToObject(data[0]);
        } catch (err) {
            throw new Error(err);
        }
    },
    getList: async (options) => {
        try {
            const { data } = await fetch(ContractRoutes.getList(options), {
                method: Method.GET,
                authenticated: true
            });
            // return !data || !data.length ? [] : data.map(parseContractToModel);
            return !data ? {contracts:[],total:0} : {total: data.total, contracts: data.contracts.map(parseContractToModel)};
        } catch (err) {
            throw new Error(err);
        }
    },

    getExperienceList: async (id,options) => {
        try {
            const { data } = await fetch(ContractRoutes.getExperienceList(id,options), {
                method: Method.GET,
                authenticated: true
            });
            // return !data || !data.length ? [] : data.map(parseContractToModel);
            return !data ? {experiences:[],total:0} : {total: data.total, experiences: data.experiences};
        } catch (err) {
            throw new Error(err);
        }
    },

    add: async (info) => {
        try {
            const { data } = await fetch(ContractRoutes.add, {
                method: Method.POST,
                authenticated: true,
                data: info,
            });
            return !data || !data.length ? false : parseContractToObject(data[0]);
        } catch (err) {
            throw new Error(err);
        }
    },

    update: async (info) => {
        try {
            const { data } = await fetch(ContractRoutes.update(info.id), {
                method: Method.PATCH,
                authenticated: true,
                data: info,
            });
            return Array.isArray(data) ? data[0] : false;
        } catch (err) {
            throw new Error(err);
        }
    },

    updateExperiences: async (id, info) => {
        try {
            const { data } = await fetch(ContractRoutes.updateExperiences(id), {
                method: Method.POST,
                authenticated: true,
                data: info,
            });
            return Array.isArray(data) ? data[0] : false;
        } catch (err) {
            throw new Error(err);
        }
    },

    deleteExperiences: async (id, expId) => {
        try {
            const { data } = await fetch(ContractRoutes.deleteExperiences(id, expId), {
                method: Method.DELETE,
                authenticated: true,
            });
            return Array.isArray(data) ? data[0] : false;
        } catch (err) {
            throw new Error(err);
        }
    },

    uploadContractPicture: async (id, image) => {
        try {
            const formData = new FormData();
            image =
                image.type && image.type !== ''
                    ? image
                    : image.slice(0, image.size, 'image/png');
            formData.append('images', image, image.name ?? `contract_${id}.png`);
            await fetch(ContractRoutes.uploadImage(id), {
                method: Method.POST,
                authenticated: true,
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
        } catch (err) {
            throw new Error(err);
        }
    },

    getQRCode: async (id) => {
        try {
            const { data } = await fetch(ContractRoutes.getQRCode(id), {
                method: Method.GET,
                authenticated: true
            });
            return Array.isArray(data) ? data[0] : false;
        } catch (err) {
            throw new Error(err);
        }
    },
};
