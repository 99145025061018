import React, { useContext, useState } from 'react';

import { ExperienceService } from '../api/services/experiences';
import { useHost } from './host';
// import { usePagging } from './pagging';

const ExperiencesContext = React.createContext(null);

export const ExperiencesProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [experiences, setExperiences] = useState([]);
  const [qrExperiences, setQrExperiences] = useState([]);
  const [bookedExperiences, setBookedExperiences] = useState([]);
  const [timeOfArrival, setTimeOfArrival] = useState(null);
  const { setData } = useHost();

  const [contractExperiences, setContractExperiences] = useState([]);
  const [contractBundleExperiences, setContractBundleExperiences] = useState([]);

//   const { setHasMorePages } = usePagging();

  // console.log('ExperienceService', ExperienceService);
  const getExperiences = async (filters, page, resetExperiences = false) => {
    try {
      setLoading(true);
      const experiencesData = await ExperienceService.get(filters, page);

      if (!experiencesData) {
        throw new Error();
      }
      console.log("experiences =======> ", [...experiences, ...experiencesData.experiences])
    //   setHasMorePages(experiencesData.hasMorePages);

      if (resetExperiences) {
        setExperiences(experiencesData.experiences);
      } else {
        setExperiences([...experiences, ...experiencesData.experiences]);
      }
      // setExperiences(experiencesData);

      return true;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const getHostelExperiences = async (code, priceOrder, modusesFilter, tourOperatorsId) => {
    try {
      console.log("context getHostelExperiences");
      setLoading(true);
      const qrData = await ExperienceService.getByHostel(code, priceOrder, modusesFilter, tourOperatorsId);
  
      if (!qrData) {
        throw new Error();
      }
  
      setData(qrData.establishmentData)
      setQrExperiences(qrData.experiences);
  
      return true;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      setLoading(false);
    }
  }
  
  const getContractExperiences = async (code, priceOrder, modusesFilter, tourOperatorsId) => {
    try {
      setLoading(true);
      const qrData = await ExperienceService.getByHostel(code.toString(16), priceOrder, modusesFilter, tourOperatorsId);
      if (!qrData) {
        throw new Error();
      }
  
      setData(qrData.establishmentData)
      setContractExperiences(qrData.experiences);
  
      return true;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      setLoading(false);
    }
  }
  const getContractBundleExperiences = async (code, priceOrder, modusesFilter, tourOperatorsId) => {
    try {
      setLoading(true);
      const qrData = await ExperienceService.getBundleByHostel(code.toString(16), priceOrder, modusesFilter, tourOperatorsId);
      if (!qrData) {
        throw new Error();
      }
      // setData(qrData.establishmentData)
      setContractBundleExperiences(qrData.bundle);
  
      return true;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      setLoading(false);
    }
  }

  // today we only manage one experience, not a cart of experiences
  const addBookedExperience = (experience) => {
    setBookedExperiences([experience]);
  }

  const addBookedExperienceWithPrice = (experience, price) => {
    experience.price = price;
    setBookedExperiences([experience]);
  }
  
  const addBookedExperienceWithQuantityPrice = (experience, price, quantity) => {
    experience.priceTotal = price;
    experience.quantity = quantity;
    setBookedExperiences([experience]);
  }

  const setBookedExperiencePrice = (experienceId, price) => {
    console.log(bookedExperiences, experienceId, price);
    const index = bookedExperiences.findIndex(exp => exp.id == experienceId);
    if (index === -1) {
      // TODO: error
    }
    const auxBookedExperience = [...bookedExperiences];
    auxBookedExperience[index].price = price;
    setBookedExperiences(auxBookedExperience);
  }

  const hasBookedExperiences = () => {
    return bookedExperiences.length > 0;
  }

  const getExperiencesUpdated = async (expId) => {
    try {
      setLoading(true);
      const experiencesData = await ExperienceService.getUpdated(expId || '');

      if (!experiencesData) {
        throw new Error();
      }
      // Home experiences expects you to set the state
      setExperiences(experiencesData);
      // Global bookings expects you to return the experiencesData
      return experiencesData;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      setLoading(false);
    }
  };

  return (
    <ExperiencesContext.Provider
      value={{
        loading,
        getExperiences,
        getHostelExperiences,
        experiences,
        addBookedExperience,
        addBookedExperienceWithPrice,
        addBookedExperienceWithQuantityPrice,
        bookedExperiences,
        setBookedExperiencePrice,
        hasBookedExperiences,
        timeOfArrival,
        setTimeOfArrival,
        qrExperiences,
        getExperiencesUpdated,
        //NEW ONES,
        contractExperiences,
        getContractExperiences,
        contractBundleExperiences, 
        getContractBundleExperiences,
      }}>
      {children}
    </ExperiencesContext.Provider>
  );
};

ExperiencesProvider.displayName = 'ExperiencesProvider';

export const useExperiences = () => useContext(ExperiencesContext);
