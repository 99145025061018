import { useHistory } from 'react-router-dom';
import { useMediaQuery } from '@material-ui/core';

import { useAuth } from 'core/contexts/auth';
// import { useExperiencers } from 'core/contexts/experiencer';
import { useStyles } from './styles';

export const useAppBar = (isHome) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const drawerWidth = isMobile ? '100vw' : 240;
  const classes = useStyles({ drawerWidth });
  const history = useHistory();

  const { role, setRole, userInfo } = useAuth();

  // const { become } = useExperiencers();

  
  // console.log(showBecomeButton, isHome);

  const setUserRole = (selectedRole) => {
    setRole(selectedRole);
  };

  return {
    classes,
    role,
    setRole: setUserRole,
    // showBecomeButton,
    onBecomeClick: false,
  };
};
