import React, { useEffect, useState } from 'react';
import {
  TextField,
  InputAdornment,
  IconButton,
  LinearProgress,
} from '@material-ui/core';
import { Button } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'core/contexts/localization';
import { VisibilityOffOutlined, VisibilityOutlined } from '@material-ui/icons';
import { AuthService } from 'core/api/services/auth';
import { ROUTES } from 'core/routing/routes';
import GenericBox from '../components/GenericBox';
import logo from 'assets/images/utriper-black-logo.png';
import { useStyles } from './styles';

const ResetPassword = () => {
  const { t: translate } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const classes = useStyles();

  const [newPassword, setNewPassword] = useState('');
  const [newPasswordErrorMessage, setNewPasswordErrorMessage] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false);

  const [confirmedPassword, setConfirmedPassword] = useState('');
  const [confirmedPasswordErrorMessage, setConfirmedPasswordErrorMessage] =
    useState('');
  const [showConfirmedPassword, setShowConfirmedPassword] = useState(false);

  const [passwordDoesnotMatch, setPasswordDoesnotMatch] = useState(false);

  const [loadingMessage, setLoadingMessage] = useState('');

  useEffect(() => {
    if (location.state === undefined || location.state.code === undefined) {
      history.replace(ROUTES.LANDING);
    }
  }, []);

  const validateNewPassword = (newPassword) => {
    if (newPassword.length === 0) {
      return translate('form.required');
    }
    if (newPassword.length < 8) {
      return translate('form.invalid.password.length');
    }
    return '';
  };

  const submit = async () => {
    let errorMessage = validateNewPassword(newPassword);
    let hasErrors = false;
    if (errorMessage.length > 0) {
      setNewPasswordErrorMessage(errorMessage);
      hasErrors = true;
    }

    errorMessage = validateNewPassword(confirmedPassword);
    if (errorMessage.length > 0) {
      setConfirmedPasswordErrorMessage(errorMessage);
      hasErrors = true;
      return;
    }

    if (hasErrors) {
      return;
    }

    if (newPassword !== confirmedPassword) {
      setPasswordDoesnotMatch(true);
      setConfirmedPasswordErrorMessage(
        translate('form.invalid.password.not_match')
      );
      hasErrors = true;
      return;
    }

    setLoadingMessage(
      `${translate('reset_password.reset_action.resetting_password')}`
    );
    try {
      const success = await AuthService.resetPassword(
        location.state.code,
        newPassword
      );
      if (success) {
        setLoadingMessage(
          `${translate('reset_password.reset_action.success')}`
        );
        await new Promise((r) => setTimeout(r, 2000));
        history.replace(ROUTES.LANDING);
      } else {
        history.replace(ROUTES.ERROR);
      }
    } catch (e) {
      history.replace(ROUTES.ERROR);
    }
  };

  const getErrorComponent = (errorMessage) => {
    return (
      <p
        style={{
          color: '#f44336',
          textAlign: 'left',
          fontFamily: 'Open sans, sans-serif',
          fontWeight: 400,
          fontSize: '0.8rem',
          fontStyle: 'italic',
          marginLeft: 14,
        }}>
        {errorMessage}
      </p>
    );
  };

  const getLoadingComponent = (message) => {
    return (
      <div
        style={{
          width: '15rem',
          margin: 0,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: `translate(-50%, -50%)`,
        }}>
        <h1 style={{ textAlign: 'center' }}>{message}</h1>
        <LinearProgress color="primary" />
      </div>
    );
  };

  const renderBox = () => {
    if (loadingMessage.length > 0) {
      return getLoadingComponent(loadingMessage);
    }
    return (
      <div style={{ marginTop: '7vh' }}>
        <GenericBox
          title={translate('reset_password.reset_action.title')}
          subtitle={translate('reset_password.reset_action.subtitle') + '.'}>
          <style>
            {`
                        .MuiInputBase-input {
                            font-size: 1rem;
                        }
                    `}
          </style>
          <TextField
            label={translate('reset_password.reset_action.new_password')}
            color="red"
            value={newPassword}
            onChange={(e) => {
              if (passwordDoesnotMatch) {
                const matchingPasswords = e.target.value === confirmedPassword;
                setPasswordDoesnotMatch(matchingPasswords);
                setConfirmedPasswordErrorMessage(
                  validateNewPassword(confirmedPassword)
                );
              }
              setNewPasswordErrorMessage(validateNewPassword(e.target.value));
              setNewPassword(e.target.value);
            }}
            className={classes.inputBox}
            id="password"
            type={showNewPassword ? 'text' : 'password'}
            // placeholder={translate("reset_password.reset_action.new_password")}
            variant="outlined"
            margin="normal"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowNewPassword(!showNewPassword)}>
                    {showNewPassword ? (
                      <VisibilityOutlined />
                    ) : (
                      <VisibilityOffOutlined />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {newPasswordErrorMessage.length > 0 &&
            getErrorComponent(newPasswordErrorMessage)}
          <TextField
            label={translate(
              'reset_password.reset_action.confirm_new_password'
            )}
            color="red"
            value={confirmedPassword}
            onChange={(e) => {
              if (passwordDoesnotMatch) {
                const matchingPasswords = e.target.value === newPassword;
                setPasswordDoesnotMatch(matchingPasswords);
              }
              setConfirmedPasswordErrorMessage(
                validateNewPassword(e.target.value)
              );
              setConfirmedPassword(e.target.value);
            }}
            className={classes.inputBox}
            id="password"
            type={showConfirmedPassword ? 'text' : 'password'}
            // placeholder={translate("reset_password.reset_action.confirm_new_password")}
            variant="outlined"
            margin="normal"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      setShowConfirmedPassword(!showConfirmedPassword)
                    }>
                    {showConfirmedPassword ? (
                      <VisibilityOutlined />
                    ) : (
                      <VisibilityOffOutlined />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {confirmedPasswordErrorMessage.length > 0 &&
            getErrorComponent(confirmedPasswordErrorMessage)}
        </GenericBox>
        <div className={classes.buttons}>
          <Button
            disabled={
              newPasswordErrorMessage.length > 0 ||
              confirmedPasswordErrorMessage.length > 0
            }
            className="triper-button bg-blue-300"
            style={{ minWidth: '7rem' }}
            onClick={async () => {
              await submit();
            }}>
            {translate('reset_password.reset')}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <div
        style={{
          width: '11rem',
          height: '4rem',
          marginInline: 'auto',
          marginTop: '5vh',
        }}>
        <img src={logo} style={{ width: 'inherit', height: 'inherit' }} />
      </div>
      {renderBox()}
    </React.Fragment>
  );
};

export default ResetPassword;
