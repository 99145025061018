import React, { useEffect, useContext, useState } from 'react';
import { isEmpty } from 'lodash';
import { ScheduleService } from 'core/api/services/schedule';
import { toast } from 'react-toastify';
import { useTranslation } from 'core/contexts/localization';
import { formatMoney } from 'core/packages/format';

const ScheduleContext = React.createContext(null);

export const ScheduleProvider = ({ children }) => {
  const [loading_, setLoading] = useState(false);
  const [schedules, setSchedules] = useState([]);
  const [allSlots, setAllSlots] = useState([]);

  const { t: translate } = useTranslation();

  const getSchedules = async () => {
    try {
      setLoading(true);
      const scheduleData = await ScheduleService.get();
      if (!scheduleData) {
        throw new Error();
      }

      setSchedules(scheduleData);

      return true;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const orderSlots = (unorderedAllSlots) => {
    if(unorderedAllSlots && unorderedAllSlots.length) {
      unorderedAllSlots.sort((a,b) => a.time.substring(0,2) - b.time.substring(0,2));
      setAllSlots(unorderedAllSlots);
    }
  };

  const getSchedulesUpdated = async (expId, subExpId, date, mod, getAllSlots = false) => {
    try {
      setLoading(true);
      setSchedules([]);
      const scheduleData = await ScheduleService.getUpdated(
        expId,
        subExpId,
        date,
        mod
      );
      console.log('hereee', scheduleData);
      if (!scheduleData) {
        throw new Error();
      }
      setSchedules(scheduleData);

        
      if(scheduleData && scheduleData.length && getAllSlots) {
        let newId = 0;
        const allSlotsAux = [];
        for(let i = 0; i < scheduleData.length; i++) {
          const schedule = scheduleData[i];
          // fetch schedule information
          const sch = await ScheduleService.getByScheduleId(
            expId,
            subExpId,
            schedule.id,
            1,
            new Date(date)
            );
          sch.slots.forEach(slot => {
            slot.id=newId;
            newId++;
            allSlotsAux.push({strPrice: `${sch.currency} ${formatMoney(sch.price)}`, price:parseFloat(sch.price), ...slot});
          });
        }
        orderSlots(allSlotsAux);
        // setAllSlots(allSlotsAux);
      }

      return true;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const create = async (data) => {
    try {
      setLoading(true);
      const scheduleData = await ScheduleService.create(data);

      if (!scheduleData) {
        throw new Error();
      }

      await getSchedules();

      return true;
    } catch (err) {
      toast.error(translate('global_bookings.errors.add_schedule'), {
        position: 'top-right',
        theme: 'colored',
      });
      console.error(err);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const createUpdated = async (expId, subExpId, values, todayDate) => {
    try {
      console.log(values);
      setLoading(true);
      const scheduleData = await ScheduleService.createUpdated(
        expId,
        subExpId,
        values
      );

      if (!scheduleData) {
        throw new Error();
      }
      await getSchedulesUpdated(expId, subExpId, todayDate);

      return true;
    } catch (err) {
      toast.error(translate('global_bookings.errors.add_schedule'), {
        position: 'top-right',
        theme: 'colored',
      });
      console.error(err);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const deleteSchedule = async (
    expId,
    subExpId,
    id,
    day,
    todayDate,
    all = false
  ) => {
    try {
      setLoading(true);

      const scheduleData = await ScheduleService.delete(
        expId,
        subExpId,
        id,
        day,
        all
      );

      await getSchedulesUpdated(expId, subExpId, todayDate);

      setLoading(false);
    } catch (err) {
      throw new Error(err);
    }
  };

  const updateSchedule = async (
    expId,
    subExpId,
    id,
    todayDate,
    data,
    all = false
  ) => {
    try {
      setLoading(true);

      const scheduleData = await ScheduleService.update(
        expId,
        subExpId,
        id,
        data,
        all
      );

      await getSchedulesUpdated(expId, subExpId, todayDate);
    } catch (err) {
      throw new Error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ScheduleContext.Provider
      value={{
        loading_,
        getSchedules,
        getSchedulesUpdated,
        createUpdated,
        schedules,
        allSlots,
        create,
        updateSchedule,
        deleteSchedule,
      }}
    >
      {children}
    </ScheduleContext.Provider>
  );
};

ScheduleProvider.displayName = 'ScheduleProvider';

export const useSchedules = () => useContext(ScheduleContext);
