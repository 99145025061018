import React from 'react';
import { useTranslation } from 'core/contexts/localization';
import { EmailBox } from './components/EmailBox';
import Buttons from './components/Buttons';
import logo from 'assets/images/utriper-black-logo.png';
import { CheckEmailBox } from './components/CheckEmailBox';
import { AuthService } from 'core/api/services/auth';

const ForgottenPassword = () => {
  const { t: translate } = useTranslation();

  const [email, setEmail] = React.useState('');
  const [emailError, setEmailError] = React.useState(null);
  const [sentEmail, setSentEmail] = React.useState(false);

  const renderError = (value, validator) => {
    const message = validator(value);
    return message.length > 0 ? (
      <p
        style={{
          color: '#f44336',
          textAlign: 'left',
          fontFamily: 'Open sans, sans-serif',
          fontWeight: 400,
          fontSize: '0.8rem',
          fontStyle: 'italic',
          marginLeft: 14,
        }}>
        {message}
      </p>
    ) : null;
  };

  const validateEmail = (email) => {
    if (email.length === 0) {
      return translate('form.required');
    }
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      return translate('form.invalid.email');
    }
    return '';
  };

  const handleEmailChange = (value) => {
    const errorComponent = renderError(value, validateEmail);
    setEmailError(errorComponent);
    setEmail(value);
    return errorComponent;
  };

  const renderBox = () => {
    if (sentEmail) {
      return <CheckEmailBox email={email} />;
    }

    return (
      <React.Fragment>
        <EmailBox
          value={email}
          setValue={handleEmailChange}
          errorComponent={emailError}
        />
        <Buttons
          disabledSend={emailError !== null}
          sendEmail={async () => {
            try {
              const success = await AuthService.forgetPassword(email);
              if (!success) {
                throw new Error('Request not accepted');
              }
              setSentEmail(true);
            } catch (err) {
              console.log(err);
            }
          }}
        />
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <div
        style={{
          width: '11rem',
          height: '4rem',
          marginInline: 'auto',
          marginTop: '5vh',
        }}>
        <img src={logo} style={{ width: 'inherit', height: 'inherit' }} />
      </div>
      <div style={{ marginTop: '7vh' }}>{renderBox()}</div>
    </React.Fragment>
  );
};

export default ForgottenPassword;
