import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  content: {
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  container: {
    padding: '60px 40px',
    [theme.breakpoints.down('xs')]: {
      padding: '60px 10px',
    },
  },
  header: {
    alignItems: 'center',
  },
  title: {
    [theme.breakpoints.down('xs')]: {
      padding: `${theme.spacing(2)}px ${theme.spacing(2)}px 0px ${theme.spacing(
        2
      )}px`,
    },
  },
  selectContainer: {
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right',
    },
    [theme.breakpoints.down('xs')]: {
      padding: `0px ${theme.spacing(2)}px`,
    },
  },
  experiencesTitle: {
    margin: `${theme.spacing(2)}px 0px`,
    [theme.breakpoints.down('xs')]: {
      padding: `0px ${theme.spacing(2)}px`,
    },
  },
  globalBookingsTitle: {
    padding: '3rem 2rem',
  },
  globalBookingsFilter: {
    margin: '0 2rem',
    display: 'inline',
  },
  globalBookingsSearchBoxContainer: {
    display: 'inline',
    margin: '0 2rem',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '20rem',
    },
  },
  globalBookingsSearchBox: {
    [theme.breakpoints.down('xs')]: {
      margin: '0.5rem 0 0 0',
    },
  },
  globalBookingsShowPrevious: {
    display: 'block',
    margin: '0.5rem 2rem',
    width: 'fit-content',
    cursor: 'pointer',
  },
}));
