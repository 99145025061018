import { fetch, Method } from 'core/api/fetch';
import { parseHostToModel } from 'core/api/parser/host';
import { parseBookingToObject, parseBookingToModel, parseBookingReservationsToModel, parseBookingReservationsToObject } from 'core/api/parser/booking'
import { parseContractToModel } from 'core/api/parser/contract';

const HostRoutes = {
  get: '/api/v1.1.0/host',
  //   update: '/api/v1.1.0/host',
  //   updateAvatar: (host) => `/api/v1.1.0/host/${host}/image`,
  getContracts: (page, size, exp, expPage, expSize) =>
    `/api/v1.1.0/host/contracts`,
  getBookings: (page, size) =>
    `/api/v1.1.0/host/bookings?page=${page}&pageSize=${size}`,
  getBooking: (id) => `/api/v1.1.0/host/booking/${id}`,
  updateBooking: (id) => `/api/v1.1.0/host/booking/${id}`,
  createBookings: (expId, subExpId, startDate, endDate) =>
    `/api/v1.1.0/host/booking?from=${startDate}&to=${endDate}`,
};

const buildGetBookingsQueryParams = ({
  experiencesId,
  email,
  bookingCode,
  showPreviousBookings,
  orderBy,
  direction
}) => {
  let query = '';
  let addedParam = false;
  if (experiencesId) {
    query += `experiencesId=[${experiencesId}]`;
    addedParam = true;
  }
  if (email) {
    query += addedParam ? `&email=${email}` : `email=${email}`;
    addedParam = true;
  }
  if (bookingCode) {
    query += addedParam
      ? `&bookingCode=${bookingCode}`
      : `bookingCode=${bookingCode}`;
    addedParam = true;
  }
  if (showPreviousBookings) {
    query += addedParam
      ? `&showPreviousBookings=${showPreviousBookings}`
      : `showPreviousBookings=${showPreviousBookings}`;
    addedParam = true;
  }
  if (orderBy) {
    query += addedParam ? `&column=${orderBy}` : `column=${orderBy}`;
    addedParam = true;
  }
  if (direction) {
    query += addedParam ? `&direction=${direction}` : `direction=${direction}`;
    addedParam = true;
  }
  return '&' + query;
};
export const HostService = {

  get: async (info) => {
    try {
      const { data } = await fetch(HostRoutes.get, {
        method: Method.GET,
        authenticated: true
      });
      return !data || !data.length ? [] : data.map(parseHostToModel);
    } catch (err) {
      throw new Error(err);
    }
  },

  //   update: async (info) => {
  //     try {
  //       const { data } = await fetch(HostRoutes.update, {
  //         method: Method.PATCH,
  //         authenticated: true,
  //         data: info,
  //       });
  //       return Array.isArray(data) ? data[0].status : false;
  //     } catch (err) {
  //       throw new Error(err);
  //     }
  //   },

  //   updateAvatar: async (id, image) => {
  //     try {
  //       const formData = new FormData();
  //       image =
  //         image.type && image.type !== ''
  //           ? image
  //           : image.slice(0, image.size, 'image/png');
  //       formData.append('images', image, image.name ?? 'avatar.png');
  //       await fetch(HostRoutes.updateAvatar(id), {
  //         method: Method.POST,
  //         authenticated: true,
  //         data: formData,
  //         headers: {
  //           'Content-Type': 'multipart/form-data',
  //         },
  //       });
  //     } catch (err) {
  //       throw new Error(err);
  //     }
  //   },

  // this method should return bookings w/ their originId on the response
  getBookings: async (
    experiencesId,
    email,
    bookingCode,
    showPreviousBookings,
    page,
    size = 20,
    orderBy,
    direction
  ) => {
    try {
      console.log('------------------------------------------');
      console.log('calling getBookings srv with args: ');
      const args = [
        ['experiencesId', experiencesId],
        ['email', email],
        ['bookingCode', bookingCode],
        ['showPreviousBookings', showPreviousBookings],
        ['page', page],
        ['size', size],
      ];
      console.table(args);
      const { data } = await fetch(
        `${HostRoutes.getBookings(
          page,
          size
        )}${buildGetBookingsQueryParams({
          experiencesId,
          email,
          bookingCode,
          showPreviousBookings,
          orderBy,
          direction
        })}`,
        {
          method: Method.GET,
          authenticated: true,
        }
      );
      // console.log('response after fetch success on getBookings srv: ', data[0]);
      return !data ? { bookings: [], total: 0 } : { total: data.total, bookings: data.bookings.map(parseBookingToModel) };
      // const result = data[0];
      // return {
      //   hasNext: result.hasNext,
      //   bookings: result.bookings.map(parseBookingToObject),
      //   totalBookings: result.totalBookings,
      // };
    } catch (err) {
      throw new Error(err);
    }
  },

  getBooking: async (id) => {
    try {
      const { data } = await fetch(HostRoutes.getBooking(id), {
        method: Method.GET,
        authenticated: true,
      });
      return !data || !data.length ? {} : parseBookingToObject(data[0]);
    } catch (err) {
      throw new Error(err);
    }
  },
  updateBooking: async (info) => {
    try {
      const { data } = await fetch(HostRoutes.updateBooking(info.id), {
        method: Method.PATCH,
        authenticated: true,
        data: info,
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return Array.isArray(data) ? data[0] : false;
    } catch (err) {
      throw new Error(err);
    }
  },

  createBooking: async (expId, subExpId, startDate, endDate, info) => {
    try {
      const { data } = await fetch(
        HostRoutes.createBookings(expId, subExpId, startDate, endDate),
        {
          method: Method.POST,
          authenticated: true,
          data: parseBookingReservationsToObject(info),
        }
      );
      return data;
    } catch (err) {
      throw new Error(err);
    }
  },

  getContracts: async (
    page,
    size = 20
  ) => {
    try {

      const { data } = await fetch(HostRoutes.getContracts(page, size), {
        method: Method.GET,
        authenticated: true,
      });

      return !data ? { contracts: [], total: 0 } : { total: data.total, contracts: data.contracts.map(parseContractToModel) };
    } catch (err) {
      throw new Error(err);
    }
  },
};
