import { isEmpty } from 'lodash';
import { fetch, Method } from 'core/api/fetch';
import {
  parseBookingToModelUpdated,
  parseBookingReservationsToModel,
  parseBookingReservationsToObject,
} from '../parser/booking';
import { parseBookingToObject } from 'core/api/parser/booking';

let expNum = window.location.href.split('/');
let id = parseFloat(expNum[expNum.length - 2]);

const bookingRoutes = {
  // getAllNoFilter: '/api/v1.1.0/experience',
  getAll: (id, subExpId, calendarRange, calendarView) =>
    `/api/v1.1.0/experience/${id}/expDetail/${subExpId}/booking?day=${calendarRange}&mod=${calendarView}`,
  create: (id, subExpId) =>
    `/api/v1.1.0/experience/${id}/expDetail/${subExpId}/booking`,
  // update route is duplicated
  update: (id, subExpId) =>
    `/api/v1.1.0/experience/${id ? id : ''}/expDetail/${subExpId ? subExpId : ''
    }/booking`,
  update: (expId, subExpId) =>
    `/api/v1.1.0/experience/${expId}/expDetail/${subExpId}/booking`,
  reservations: (expId, subExpId, startDate, endDate) =>
    `/api/v1.1.0/experience/${expId}/expdetail/${subExpId}/booking?from=${startDate}&to=${endDate}&mod=0&day=${startDate.substring(
      0,
      startDate.indexOf('T')
    )}`,
  addReservation: (expId, subExpId, startDate, endDate) =>
    `/api/v1.1.0/experience/${expId}/expdetail/${subExpId}/booking?from=${startDate}&to=${endDate}`,
  updateReservation: (expId, subExpId) =>
    `/api/v1.1.0/experience/${expId}/expdetail/${subExpId}/booking`,
  getById: (expId, expDetailId, bookingId) =>
    `/api/v1.1.0/experience/${expId}/expdetail/${expDetailId}/booking/${bookingId}`,

  // NEW APIS
  addContractBooking: () => `/api/v1.1.0/host/booking`,
  addContractBookingPayment: () => `/api/v1.1.0/host/booking/confirmation`,
  deleteContractBookingPayment: (info) => `/api/v1.1.0/host/booking/confirmation/${info.paymentId}`,
};

export const BookingStatus = {
  UNDEFINED: { value: 0, label: 'undefined' },
  PENDING: { value: 1, label: 'pending' },
  BOOKED: { value: 2, label: 'booked' },
  CANCELLED: { value: 3, label: 'cancelled' },
  CONFIRMED: { value: 4, label: 'confirmed' },
};

export const BookingOrigin = {
  UNDEFINED: { value: 0, label: 'undefined' },
  WEBAPP: { value: 1, label: 'webapp' },
  LANDING: { value: 2, label: 'landing' },
  MANUAL: { value: 3, label: 'manual' },
  MOBILEAPP: { value: 4, label: 'mobileapp' },
  CODE: { value: 5, label: 'code' },
  QR: { value: 6, label: 'qr' },
  CATALOG: { value: 7, label: 'catalog' },
};

export const getLabelByBookingStatusId = (id) => {
  const bookingStatus = Object.values(BookingStatus).find(
    (status) => status.value === id
  );

  return bookingStatus ? bookingStatus.label : 'undefined';
};

export const getLabelByBookingOriginId = (id) => {
  const bookingOrigin = Object.values(BookingOrigin).find(
    (origin) => origin.value === id
  );

  return bookingOrigin ? bookingOrigin.label : 'undefined';
};

export const BookingService = {
  // getAllBookings: async () => {
  //   try {
  //     const { data } = await fetch(bookingRoutes.getAllNoFilter, {
  //       method: Method.GET,
  //       authenticated: true,
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //     });

  //     return isEmpty(data) ? [] : data;
  //   } catch (err) {
  //     throw new Error(err);
  //   }
  // },
  get: async (calendarRange, expId, subExpId, calendarView) => {
    try {
      const { data } = await fetch(
        bookingRoutes.getAll(expId, subExpId, calendarRange, calendarView),
        {
          method: Method.GET,
          authenticated: true,
        }
      );

      return isEmpty(data) ? [] : data.map(parseBookingToModelUpdated);
    } catch (err) {
      throw new Error(err);
    }
  },

  // ToDo: add 'additional' field on POST Global Booking
  create: async ({
    date,
    email,
    firstName,
    lastName,
    mobilePhone,
    mobilePhoneCountryCode,
    quantity,
    currency,
    status,
    price,
    expId,
    subExpId,
  }) => {
    const resArr = [];
    const dataToSend = {
      date,
      email,
      firstName,
      lastName,
      status,
      quantity,
    };
    if (mobilePhone) {
      dataToSend.mobilePhone = mobilePhone;
      dataToSend.mobilePhoneCountryCode = mobilePhoneCountryCode;
    }
    if (currency) {
      dataToSend.currency = currency;
    }
    if (price) {
      dataToSend.price = price;
    }
    try {
      await fetch(bookingRoutes.create(expId, subExpId), {
        method: Method.POST,
        authenticated: true,
        data: dataToSend,
      }).then((res) => {
        //console.log(res, 'save res');
        resArr.push(res);
      });
      return resArr;
    } catch (err) {
      throw new Error(err);
    }
  },

  update: async (info, subExpId) => {
    try {
      const { data } = await fetch(bookingRoutes.update(id, subExpId), {
        method: Method.PATCH,
        authenticated: true,
        data: parseBookingToObject(info),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return isEmpty(data) ? {} : data;
    } catch (err) {
      throw new Error(err);
    }
  },

  updateUpdated: async (info, expId, subExpId) => {
    try {
      const { data } = await fetch(bookingRoutes.update(expId, subExpId), {
        method: Method.PATCH,
        authenticated: true,
        data: parseBookingToObject(info),
      });
      return isEmpty(data) ? {} : data;
    } catch (err) {
      throw new Error(err);
    }
  },

  getReservations: async (expId, subExpId, startDate, endDate) => {
    try {
      const { data } = await fetch(
        bookingRoutes.reservations(expId, subExpId, startDate, endDate),
        {
          method: Method.GET,
          authenticated: true,
        }
      );
      return isEmpty(data) ? [] : data.map(parseBookingReservationsToModel);
    } catch (err) {
      throw new Error(err);
    }
  },

  addReservation: async (expId, subExpId, startDate, endDate, info) => {
    try {
      const { data } = await fetch(
        bookingRoutes.addReservation(expId, subExpId, startDate, endDate),
        {
          method: Method.POST,
          authenticated: true,
          data: parseBookingReservationsToObject(info),
        }
      );
      return data;
    } catch (err) {
      throw new Error(err);
    }
  },

  addContractBooking: async (expId, subExpId, info) => {
    try {
      const { data } = await fetch(bookingRoutes.addContractBooking(), {
        method: Method.POST,
        authenticated: true,
        data: parseBookingReservationsToObject(info, expId, subExpId),
      }
      );
      return data;
    } catch (err) {
      throw new Error(err);
    }
  },

  addContractBookingPayment: async (info) => {
    try {
      const { data } = await fetch(
        bookingRoutes.addContractBookingPayment(),
        {
          method: Method.POST,
          authenticated: true,
          data: info,
        }
      );
      return data;
    } catch (err) {
      throw new Error(err);
    }
  },

  deleteContractBookingPayment: async (info) => {
    try {
      const { data } = await fetch(
        bookingRoutes.deleteContractBookingPayment(info),
        {
          method: Method.DELETE,
          authenticated: true,
          data: info,
        }
      );
      return data;
    } catch (err) {
      throw new Error(err);
    }
  },

  updateReservation: async (
    expId,
    subExpId,
    bookingId,
    date,
    statusId,
    cancelReason,
    additional,
    email,
    tickets,
    totalPrice,
    paidPrice,
    mobilePhone,
    mobilePhoneCountryCode,
    triper
  ) => {
    try {
      if (
        statusId === BookingStatus.CANCELLED.value &&
        (!cancelReason || cancelReason.length === 0)
      ) {
        throw new Error('Cancel reason is empty!');
      }

      // match firstName and allow multiple lastNames on triper
      const firstNameRegex = /^[a-zA-Z]{1,}/;
      const lastNameRegex = /\s*\w*\s*([\w\s,;'\"]+)/gi;

      const firstNameToNormalize = triper.match(firstNameRegex).toString();
      let capturedLastNames = triper.replace(lastNameRegex, '$1');
      const lastNameToNormalize = capturedLastNames.toString();

      // normalize all to Title Case
      function titleCase(string) {
        let word = string.toLowerCase().split(' ');
        for (let i = 0; i < word.length; i++) {
          word[i] = word[i][0].toUpperCase() + word[i].slice(1);
        }
        word.join(' ');
        return word;
      }

      const firstNameArr = titleCase(firstNameToNormalize);
      const lastNameArr = titleCase(lastNameToNormalize);

      const firstName = firstNameArr[0];
      const lastName = lastNameArr.join(' ');

      const body = {
        id: bookingId,
        date,
        status: statusId,
        additional,
        email,
        quantity: tickets,
        price: totalPrice,
        paidPrice,
        mobilePhone,
        mobilePhoneCountryCode,
        firstName,
        lastName,
      };

      if (statusId === BookingStatus.CANCELLED.value) {
        body.cancelReason = cancelReason;
      }

      const { data } = await fetch(
        bookingRoutes.updateReservation(expId, subExpId),
        {
          method: Method.PATCH,
          authenticated: true,
          data: body,
        }
      );
      return data;
    } catch (err) {
      throw new Error(err);
    }
  },

  getBookingById: async (expId, subExpId, bookingId) => {
    try {
      const { data } = await fetch(
        bookingRoutes.getById(expId, subExpId, bookingId),
        {
          method: Method.GET,
          authenticated: true,
        }
      );
      return isEmpty(data) ? {} : parseBookingToObject(data[0]);
    } catch (err) {
      throw new Error(err);
    }
  },
};
