import { useEffect } from 'react';
import Layout from 'components/Layout';
import { useAuth } from 'core/contexts/auth';
import { useExperiencer } from 'core/contexts/experiencer';
// import TriperProfilePage from 'modules/Profile/Triper';
// import TriperProfilePage from 'modules/Profile/Triper/profile';
// import ExperiencerProfilePage from 'modules/Profile/Experiencer';

import { useStyles } from './styles';
import CircularIndeterminate from 'core/libs/core-ui/components/Progress';

const ExperiencerPage = () => {
  const { role } = useAuth();
  const { getExperiencer, experiencer, loading } = useExperiencer();

  const classes = useStyles();

  useEffect(() => {
    // const usr = await getUserInfoSync();
    getExperiencer();
  }, []);

  return (
      <Layout contentClassName={classes.content}>
      // {/* {role === 'triper' && <TriperProfilePage />} */}
      // {/* {admin === 'admin' && <ExperiencerProfilePage />} */}

        <div className=''>
            { loading ? (
                        <CircularIndeterminate />
                    ) : (
                      <table>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>NAME</th>
                            </tr>
                        </thead>
                        <tbody>
                        {experiencer?.map((exp) => (
                            <tr>
                                <td>{exp.id}</td>
                                <td>{exp.name}</td>
                            </tr>
                        // <Grid key={experiencer?.id} item xs={12} sm={6} md={4}>
                        //     <ListItem
                        //     button
                        //     className={classes.listItem}
                        //     onClick={() => history.push(`/edit/experience/${experience?.id}`)}
                        //     >
                        //     <ListItemText primary={experiencer?.name} />
                        //     <ListItemIcon>
                        //         <ChevronRight className={classes.icon} />
                        //     </ListItemIcon>
                        //     </ListItem>
                        // </Grid>
                        // ))
                    ))
                    }
          </tbody>
        </table> 
        )}
        </div>
     </Layout>
  );
};

ExperiencerPage.displayName = 'ExperiencerPage';

export default ExperiencerPage;
