import { fetch, Method } from 'core/api/fetch';
import { parseExperiencerToModel } from 'core/api/parser/experiencer';

const ExperiencerRoutes = {
  get: '/api/v1.1.0/admin/experiencer',
//   update: '/api/v1.1.0/experiencer',
//   updateAvatar: (experiencer) => `/api/v1.1.0/experiencer/${experiencer}/image`,
//   getBookings: (page, size) =>
//     `/api/v1.1.0/experiencer/bookings?page=${page}&size=${size}`,
};

// const buildGetBookingsQueryParams = ({
//   experiencesId,
//   email,
//   bookingCode,
//   showPreviousBookings,
// }) => {
//   let query = '';
//   let addedParam = false;
//   if (experiencesId) {
//     query += `experiencesId=[${experiencesId}]`;
//     addedParam = true;
//   }
//   if (email) {
//     query += addedParam ? `&email=${email}` : `email=${email}`;
//     addedParam = true;
//   }
//   if (bookingCode) {
//     query += addedParam
//       ? `&bookingCode=${bookingCode}`
//       : `bookingCode=${bookingCode}`;
//     addedParam = true;
//   }
//   if (showPreviousBookings) {
//     query += addedParam
//       ? `&showPreviousBookings=${showPreviousBookings}`
//       : `showPreviousBookings=${showPreviousBookings}`;
//     addedParam = true;
//   }
//   return '&' + query;
// };
export const ExperiencerService = {

  get: async (info) => {
    try {
      const { data } = await fetch(ExperiencerRoutes.get, {
        method: Method.GET,
        authenticated: true
      });
      return !data || !data.length ? [] : data.map(parseExperiencerToModel);
    } catch (err) {
      throw new Error(err);
    }
  },

//   update: async (info) => {
//     try {
//       const { data } = await fetch(ExperiencerRoutes.update, {
//         method: Method.PATCH,
//         authenticated: true,
//         data: info,
//       });
//       return Array.isArray(data) ? data[0].status : false;
//     } catch (err) {
//       throw new Error(err);
//     }
//   },

//   updateAvatar: async (id, image) => {
//     try {
//       const formData = new FormData();
//       image =
//         image.type && image.type !== ''
//           ? image
//           : image.slice(0, image.size, 'image/png');
//       formData.append('images', image, image.name ?? 'avatar.png');
//       await fetch(ExperiencerRoutes.updateAvatar(id), {
//         method: Method.POST,
//         authenticated: true,
//         data: formData,
//         headers: {
//           'Content-Type': 'multipart/form-data',
//         },
//       });
//     } catch (err) {
//       throw new Error(err);
//     }
//   },

// this method should return bookings w/ their originId on the response
//   getBookings: async (
//     experiencesId,
//     email,
//     bookingCode,
//     showPreviousBookings,
//     page,
//     size = 20
//   ) => {
//     try {
//       console.log('------------------------------------------');
//       console.log('calling getBookings srv with args: ');
//       const args = [
//         ['experiencesId', experiencesId],
//         ['email', email],
//         ['bookingCode', bookingCode],
//         ['showPreviousBookings', showPreviousBookings],
//         ['page', page],
//         ['size', size],
//       ];
//       console.table(args);
//       const { data } = await fetch(
//         `${ExperiencerRoutes.getBookings(
//           page,
//           size
//         )}${buildGetBookingsQueryParams({
//           experiencesId,
//           email,
//           bookingCode,
//           showPreviousBookings,
//         })}`,
//         {
//           method: Method.GET,
//           authenticated: true,
//         }
//       );
//       console.log('response after fetch success on getBookings srv: ', data[0]);
//       const result = data[0];
//       return {
//         hasNext: result.hasNext,
//         bookings: result.bookings.map(parseBookingToObject),
//         totalBookings: result.totalBookings,
//       };
//     } catch (err) {
//       throw new Error(err);
//     }
//   },
};
