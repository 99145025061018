import React, { useContext, useState, useEffect } from 'react';
import { AuthService } from 'core/api/services/auth';
import { getSession, setSession, removeSession } from 'core/api/session';
import { toast } from 'react-toastify';
import { useTranslation } from 'core/contexts/localization';

const initialUserInfo = {
  role: 'admin',
};

const initialAuthInfo = null;

const ROLE_KEY = '@GlobalTriper/role';

const AuthContext = React.createContext(null);

export const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [authInfo, setAuthInfo] = useState(initialAuthInfo);
  const [userInfo, setUserInfo] = useState(initialUserInfo);
  // NB: We are hiding the become Triper/TO btn, so we no longer will set the role on localStorage
  // The logged in user will be experiencer by default
  const [role, setUserRole] = useState('admin');
  const { t: translate } = useTranslation();

  useEffect(
    () => {
      getUserInfo();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getUserInfo = () => {
    const authData = getSession();
    if (authData) {
      setAuthInfo(authData);
      AuthService.me().then((userData) => setUserInfo(userData));
    }
  };

  const getUserInfoSync = async () => {
    const authData = getSession();
    if (authData) {
      const userData = await AuthService.me();
      console.log('USER DATA', userData);
      setUserInfo(userData);
      return userData;
    }
  };

  const login = async (data) => {
    try {
      setLoading(true);
      const authData = await AuthService.login({ data });

      if (!authData) {
        throw new Error();
      }

      setSession(authData);

      const userData = await AuthService.me();

      if (!userData) {
        throw new Error();
      }

      setAuthInfo(authData);
      setUserInfo(userData);

      setLoading(false);

      return true;
    } catch (err) {
      setLoading(false);
      toast.error(translate('landing.login.error'), {
        position: 'top-right',
        theme: 'colored',
      });
      console.error(err);
      return false;
    }
  };

  const signUp = async (data) => {
    try {
      setLoading(true);
      const signInData = await AuthService.signUp({ data });

      if (!signInData) {
        throw new Error();
      }

      setLoading(false);

      return true;
    } catch (err) {
      setLoading(false);
      toast.error(translate('landing.sign_up.error'), {
        position: 'top-right',
        theme: 'colored',
      });
      console.error(err);
      return false;
    }
  };

  const setRole = (role) => {
    localStorage.setItem(ROLE_KEY, JSON.stringify(role));
    setUserRole(role);
  };

  const logout = async () => {
    const session = getSession();
    if (session != null) {
      await AuthService.logout({ accessToken: session });
    }
    removeSession();
    setAuthInfo(initialAuthInfo);
    setUserInfo(initialUserInfo);
  };

  const googleLogin = async () => {
    try {
      setLoading(true);
      const authData = await AuthService.googleLogin();

      if (!authData) {
        throw new Error();
      }

      setSession(authData);

      const userData = await AuthService.me();

      if (!userData) {
        throw new Error();
      }

      setAuthInfo(authData);
      setUserInfo(userData);

      setLoading(false);

      return true;
    } catch (err) {
      setLoading(false);
      toast.error(translate('landing.login.error'), {
        position: 'top-right',
        theme: 'colored',
      });
      console.error(err);
      return false;
    }
  };

  const restoreSession = async (authData) => {
    try {
      setLoading(true);
      // const authData = await AuthService.restoreSession();

      if (!authData) {
        throw new Error();
      }

      setSession(authData);

      const userData = await AuthService.me();

      if (!userData) {
        throw new Error();
      }

      setAuthInfo(authData);
      setUserInfo(userData);

      setLoading(false);

      return true;
    } catch (err) {
      setLoading(false);
      toast.error(translate('landing.login.error'), {
        position: 'top-right',
        theme: 'colored',
      });
      console.error(err);
      return false;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        loading,
        login,
        logout,
        signUp,
        authInfo,
        userInfo,
        role,
        setRole,
        getUserInfo,
        getUserInfoSync,
        googleLogin,
        restoreSession,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

AuthProvider.displayName = 'AuthProvider';
