import {
    Grid,
    Typography,
    Avatar,
    IconButton,
    ButtonBase,
  } from '@material-ui/core';
  import { useHistory } from 'react-router-dom';
  import { useEffect } from 'react';
  import { useStyles } from './styles';
  import { ROUTES } from 'core/routing/routes';
  import { useAuth } from 'core/contexts/auth';
  import EditIcon from 'assets/icons/edit.svg';
  
  const ExperiecerData = () => {
    const name = 'Colorious';
    const description = '';
  
    const classes = useStyles();
    const history = useHistory();
  
    const { userInfo, getUserInfoSync } = useAuth();
  
    useEffect(() => { getUserInfoSync() }, []);
  
    return (
      <>
        <Grid container className={classes.userDataGrid} style={{background: `url(${userInfo?.host?.backgorundPictureURL}) no-repeat center center local`}}>
          <Grid item xs={12} md={1} className={classes.userDataGrid}>
            <ButtonBase
              className={classes.container}
              onClick={() => history.push(ROUTES.PROFILE)}>
              <Avatar
                className={classes.avatar}
                src={
                  userInfo?.host?.pictureURL
                    ? `${process.env.REACT_APP_CDN_URL}${userInfo?.host?.pictureURL}`
                    : null
                }
              />
            </ButtonBase>
          </Grid>
          <Grid item xs={12} md={8} className={classes.userDataGrid}>
            <div className={classes.textContainer}>
              <div className={classes.nameContainer}>
                <Typography variant="h5" className={classes.text} noWrap>
                 Welcome {userInfo?.host?.name || name} !
                </Typography>
                {/* <IconButton
                onClick={() => history.push(ROUTES.PROFILE)}
                aria-label="edit"
                color="primary"
                className={classes.editButton}>
                <img alt="edit" style={{ width: '1em' }} src={EditIcon} />
              </IconButton> */}
              </div>
              {/* <Typography variant="h6" className={classes.text}>
                {userInfo?.experiencer?.description || description}
              </Typography> */}
            </div>
          </Grid>
        </Grid>
      </>
    );
  };
  
  ExperiecerData.displayName = 'ExperiecerData';
  
  export default ExperiecerData;
  