import React, { useContext, useState } from 'react';
import { ExperiencerService } from 'core/api/services/experiencer';
import { useAuth } from 'core/contexts/auth';

const ExperiencerContext = React.createContext(null);

export const ExperiencerProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [experiencer, setExperiencer] = useState([]);

  const { userInfo, getUserInfo } = useAuth();

  const getExperiencer = async () => {
    try {
      
      setLoading(true);
      const result = await ExperiencerService.get();

      // location.reload();
      if (!result) {
        throw new Error();
      }
      setExperiencer(result);
      return true;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      setLoading(false);
    }
  };

  // const update = async (values) => {
  //   try {
  //     setLoading(true);
  //     const result = await ExperiencerService.update(values.experiencer);
  //     console.log('SENT===', values.experiencer, 'RECEIVED===', result);
  //     if (!result) {
  //       throw new Error();
  //     }

  //     getUserInfo();

  //     return result;
  //   } catch (err) {
  //     console.error(err);
  //     return false;
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  return (
    <ExperiencerContext.Provider
      value={{
        getExperiencer,
        // update,
        loading,
        experiencer
      }}>
      {children}
    </ExperiencerContext.Provider>
  );
};

ExperiencerProvider.displayName = 'ExperiencerProvider';

export const useExperiencer = () => useContext(ExperiencerContext);
